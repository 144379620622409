
import { defineComponent, ref } from 'vue';

import AppCheckbox from '@/components/stateless/AppCheckbox.vue';

export default defineComponent({
  name: 'Checkbox',

  components: { AppCheckbox },

  setup() {
    const isChecked = ref<boolean>(false);
    const isChecked2 = ref<boolean>(true);

    return { isChecked, isChecked2 };
  }
});
