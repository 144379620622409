<template>
  <div class="text-primary text-3xl text-center p-7 border-b border-grey-fp-30 mb-20">Checkbox</div>
  <div class="mb-20">
    <p class="mb-10 text-md">Default</p>
    <AppCheckbox v-model="isChecked" />
  </div>
  <div>
    <p class="mb-10 text-md">Disabled</p>
    <AppCheckbox v-model="isChecked2" disabled />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppCheckbox from '@/components/stateless/AppCheckbox.vue';

export default defineComponent({
  name: 'Checkbox',

  components: { AppCheckbox },

  setup() {
    const isChecked = ref<boolean>(false);
    const isChecked2 = ref<boolean>(true);

    return { isChecked, isChecked2 };
  }
});
</script>